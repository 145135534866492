// Insert your styling here.

img.img-responsive {
  display: inline;
}

.bg {
  background: url(../images/paralax-bg.png) repeat;
  position: absolute;
  width: 100%;
  height: 300%;
  bottom:0;
  left:0;
  z-index: -1;
}
.parallax-content {
  color: #fff;
  font-family: arial;
  width: 500px;
  margin: auto;
  line-height: 20px;
  font-size: 16px;
}

.page-node-129, .page-node-118 {
  h1.page-header { color: @green; font-size: 34px; margin: 34px auto 12px;}
}
.not-front.page-node-118, .not-front.page-node-129 {
  .main-container.container { margin-bottom: 20px;}
  .footer { margin-top: 20px;}
}
#node-118, #node-129 {
  text-align: center;
  p { font-size: 20px;}
  a { font-size: 14px; color: #688d9d; text-decoration: underline;
    &:hover { color: @blue-dark;}
  }
}

section#block-git-ornitolog-landing-mail-confirmation {
  border: solid 6px #ebebeb;
  margin-top: 30px;
  .row { width: 870px; margin: 0 auto; max-width: 100%; padding: 30px 15px 26px;}
  .confirm-item { margin-bottom: 20px;}
  h3 { text-transform: uppercase; color: #000; margin: 16px auto 12px; font-size: 18px;}
  h4 { font-size: 14px; color: #6c6c6c; margin-bottom: 12px;}
  p { text-align: center; font-family: 'Open Sans'; font-size: 20px;}
  .btn-primary { width: 100%; font-family: 'Crete Round'; text-transform: uppercase; font-size: 18px; background: @blue-dark; border-color: @blue-dark;
    &:hover {background: lighten(@blue-dark, 5); border-color:lighten(@blue-dark, 5);}
  }
}


.views-field.views-field-field-www {
  padding-top: 10px; padding-bottom: 25px;
  a { color: rgb(146, 190, 27);
    &:hover {color: lighten(rgb(146, 190, 27), 15);}
  }
}

.view-contact-map.view-id-contact_map.view-display-id-city_contact_info {
  .view-content { float: left; border: solid 1px #ddd; border-radius: @border-radius-base;}
  font-family: 'Open Sans';
  h3 { padding: 20px 10px; font-size: 17px; margin:0; color: #333; border-bottom: solid 1px #ddd;}
  h4 { text-transform: uppercase;}
  ul { margin: 0; padding: 0; list-style: none;}
  a:hover { color: @green;}
  .field-label { font-weight: normal;}
  .col-xs-12 { text-align: left;}
}

section#block-git-ornitolog-landing-contact-header {
  text-align: center;
}

.not-logged-in.node-type-webform .node-webform {
  .form-actions{ position: relative; top: -40px;}
}

.page-node-30 .btn-default {
  border-radius: 3px; color: #fff; background: #47bd00; float:right; border-color:#47bd00; text-transform:uppercase;font-weight:bold; padding:10px 50px;
  &:hover { background: lighten(#47bd00, 5); border-color:lighten(#47bd00, 5); }
}

form#webform-client-form-30 .form-textarea-wrapper.resizable.textarea-processed.resizable-textarea {
  resize: none;
}

form#webform-client-form-30 {
  input { height: 40px; width: 100%; }
  .webform-component--telefon, .webform-component--e-mail { width: 50%; float: left;}
  .webform-component--e-mail { padding-right: 20px;}
  textarea { width: 100%; resize: none;}
}

section#block-git-ornitolog-landing-hero-image {
  position: relative;
  .text { position: absolute; top: 50%; left: 50%; transform: translateY(-50%) translateX(-50%); text-align: center; color: #fff; width: 720px; max-width: 100%;}
  h1 { text-transform: uppercase; font-size: 60px; font-family: 'Crete Round';}
  p { font-size: 24px; font-family: 'Open Sans';}
}

.view-id-contact_map .views-row { min-height: 240px;}

section#block-views-contact-map-footer-one-city {
  width:1170px;max-width:100%;margin:0 auto;
  h4 { text-transform: uppercase; font-family: 'Crete Round'; color: #fff;}
  ul { margin: 0; padding: 0; list-style: none;}
  a:hover { color: @green; cursor: pointer;}
  .field-label { font-weight: normal;}
  .sm-icons {
    padding-top: 40px;
    a {
      text-align: center;
    }
  }
  img {
    filter: grayscale(1);
    -webkit-transition: all .5s ease-in-out;
    &:hover {
      -webkit-filter: grayscale(0);
      -webkit-transform: scale(1.1);
    }
  }
  .text-right { padding-top: 30px; clear: both;}
}


section#block-git-ornitolog-landing-volunteers-menu {
  background-color: #0072bc; position: absolute; width: 100%; left: 0; margin-top: 0;
  .menu { max-width: 100%; margin: 0 auto; padding: 10px 15px; width:1170px; float: none; }
  ul {
    li {
      a {
        color: #fff;  text-transform: uppercase; transition:all 500ms; font-size: 18px; cursor: pointer;
        &.join-us { background-color: #f8b418;}
      }
      &.active a { background-color: #fff;  color: #0072bc; transition:all 500ms; }
    }
  }
  .nav > li > a:hover, .nav > li > a:focus { color: #0072bc; background-color: #fff; }
}
.page-wolontariusze {
  .top-content { margin-top: 70px;  }
}
.view-id-notifications_map .text-center { float: left; margin-top: 20px;}


@media (max-width: 480px) {
  .confirm-item { width: 100%;}
  .view-contact-map.view-id-contact_map.view-display-id-city_contact_info .col-xs-12 {
    .col-xs-3, .col-xs-9 { width: 100%; text-align: center;}
    .field-collection-view .content { width: 130px; margin: 0 auto;}
  }

}

@media (max-width: 540px) {
  .not-logged-in.node-type-webform .node-webform {
    .g-recaptcha { float: right; margin-bottom: 20px;}
    .form-actions{ position: static;}
  }
}

@media (max-width: 767px) {
  section#block-views-contact-map-footer-one-city {
    .field-collection-view .content { width: 130px; margin: 0 auto;}
    .sm-icons { width: 240px; margin: 0 auto; float: none; clear: both;}
  }
  section#block-git-ornitolog-landing-volunteers-menu {
    ul {
      li {
        a { font-size: 12px; }
      }
    }
  }
}

@media (max-width: 992px) {
  .node-type-webform .node-webform {
    .group-left, .group-right { width: 100%;}
    .view-id-contact_map.view-display-id-city_contact_info {
      .view-content { width: 100%;}
    }
    .webform-client-form { margin-top: 20px;}
  }

  .view-contact-map.view-id-contact_map.view-display-id-block_1 {
    .view-header .views-row { margin-bottom: 20px;}
  }

}
@media (min-width: 993px) and (max-width: 1200px) {
  .not-logged-in.node-type-webform .node-webform {
    .g-recaptcha { float: right; margin-bottom: 20px;}
    .form-actions{ position: static;}
  }
}