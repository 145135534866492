// Insert your styling here.//
header#navbar .collapse {display: block;}

.tb-megamenu .btn-navbar{display: block;}
@media (max-width: 979px) {
  #block-block-7 {display: none;}
  #navbar button.btn.btn-navbar.tb-megamenu-button { width: 30px; height: 30px;
    i { display: none;}
  }
}
@media screen and (min-width: 980px) {
  #block-tb-megamenu-main-menu .tb-megamenu .dropdown-menu { top: 150px;}
  button.btn.btn-navbar.tb-megamenu-button.menuIstance-processed { display: none;}
}

@media (max-width: 1200px) {
  #navbar, header#navbar { max-height: none; height: auto;
    .container { width: 1200px; max-width: 100%; height: auto; max-height: none;
      .navbar-collapse {max-height: none;}
      .collapse { visibility: visible;}
  } }
}

//#block-block-7{width:30px;height:40px;margin-top:28px;float:right;transition-duration:300ms; display: block;}
//#navbar.sticky #block-block-7{margin-top:15px;transition-duration:300ms;}
//.fix-menu #block-block-7{margin-right:5px;transition:300ms;}
//#block-block-7 span{height:34px;width:34px;position:relative;float:left;z-index:9999;background:url(../images/sprite.png) no-repeat;background-position:-7px -2px;transition-duration:300ms;}
//#block-block-7.active span{background-position:-59px -2px;transition-duration:300ms;}

